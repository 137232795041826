@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%);
  --foreground: #ededed;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-control-attribution,
.leaflet-control-zoom {
  display: none;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #223A65 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}
*::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #92a3c2;
}

*::-webkit-scrollbar-track:hover {
  background-color: #92a3c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #92a3c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #355a9d;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #355a9d;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #355a9d;
}

.mompox-blue {
  background-color: #182961;
}

.mompox-orange {
  background-color: #F6AA0F;
}

.mompox-light-blue {
  background-color: #77a1ec;
}

.text-mompox-blue {
  color: #182961;
}

.text-mompox-orange {
  color: #F6AA0F;
}

.text-mompox-light-blue {
  color: #77a1ec;
}

.login-bg {
  background-image: url("/public/login-bg.jpg");
  object-fit: cover;
  background-position: center center;
  background-size: 100vw 100vh;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 10px;
  width: fit-content;
  color: black;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #77a1ec;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #77a1ec;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}